import React from "react";

class Roadmap extends React.Component {
  render() {
    return (
      <div>
        <roadmap id = "roadmap-section">
            <div className="container">
                <div className="roadmap-header">
                    <h1>Roadmap</h1>
                </div>
                <div class="cards">
                    <div class="card">
                        <h2>Stage 1</h2>
                        <p>Establish a commanding social media presence by launching compelling platforms on Twitter and Telegram, cultivating an interactive community space.</p>
                    </div>
                    <div class="card">
                        <h2>Stage 2</h2>
                        <p>Foster a dedicated community centered around $PETE enthusiasts, creating a passionate and engaged following.</p>
                    </div>
                    <div class="card">
                        <h2>Stage 3</h2>
                        <p>Attain listings on premier cryptocurrency platforms like Coingecko and Coinmarketcap, elevating visibility and credibility in the broader market.</p>
                    </div>
                    <div class="card">
                        <h2>Stage 4</h2>
                        <p>Achieve the milestone of 10,000 holders and pursue listing on major cryptocurrency exchanges (CEX), ensuring wider accessibility and liquidity for $PETE.</p>
                    </div>
                    <div class="card">
                        <h2>Stage 5</h2>
                        <p>Unveil exclusive Captain Pete $PETE merchandise, providing our community with the opportunity to celebrate and engage with the project through unique and cherished collectibles.</p>
                    </div>
                    </div>
            </div>
        </roadmap>
      </div>
    );
  }
}

export default Roadmap;