import React, { Component } from "react";
import petelogo from "../img/pete logo.png";
import Telegram from "../img/Telegram_logo.svg.webp"
import Twitter from "../img/X_logo_2023.svg.png"


class Home extends Component {
  componentDidMount() {
    const burger = document.getElementById('burger');
    const navLinks = document.querySelector('.nav-links');

    burger.addEventListener('click', this.handleBurgerClick);
  }

  componentWillUnmount() {
    const burger = document.getElementById('burger');
    burger.removeEventListener('click', this.handleBurgerClick);
    console.log('Component is about to unmount!');
  }

  handleBurgerClick = () => {
    const navLinks = document.querySelector('.nav-links');
    navLinks.classList.toggle('active');
  };

  scrollToAbout = () => {
    const aboutSection = document.getElementById('about-section');
    aboutSection.scrollIntoView({ behavior: 'smooth' });
  };

  scrollToPartners = () => {
    const aboutSection = document.getElementById('partners');
    aboutSection.scrollIntoView({ behavior: 'smooth' });
  };
  scrollToRoadmap = () => {
    const aboutSection = document.getElementById('roadmap-section');
    aboutSection.scrollIntoView({ behavior: 'smooth' });
  };

  render() {
    return (
      <div>
        <header id = "home-section">
          <div className="container">
            <nav className="navbar">
              <img src={petelogo} alt="micky logo" className="logo" />
              <ul className="nav-links">
                <li><a href="#">Home</a></li>
                <li onClick={this.scrollToAbout}><a href="#">About</a></li>
                <li onClick={this.scrollToRoadmap}><a href="#">Roadmap</a></li>
                <li onClick={this.scrollToPartners}><a href="#">Partner</a></li>
                <button>Buy $PETE</button>
                <button>View Chart</button>
              </ul>
              <i className="fas fa-bars fa-2x" id="burger"></i>
            </nav>
            <section className="showcase">
              <h1>Steamboat Pete</h1>
              <p>Introducing Captain Pete who is the boss of Micky. Pete heard Micky wanders around again and trouble on blockchain alone. Pete now has no choice but to show who is the real boss of Steamboat Wille.</p>
              <div className="logos">
                <button className="custom-btn btn-16">Buy $PETE</button>
                <a href="https://t.me/Steamboatsol" target="_blank" rel="noopener noreferrer" class="telegram-logo">
                <img src={Telegram} alt="telegram" className="telegram-logo" />
                </a>
                <a href="https://twitter.com/steamboatsol" target="_blank" rel="noopener noreferrer" class="twitter-logo">
                <img src={Twitter} alt="twitter" className="twitter-logo" />
                </a>
              </div>
            </section>
          </div>

          <div className="video-container">
            
          </div>
        </header>
      </div>
    );
  }
}

export default Home;
