import React, { Component } from "react";
import Petemicky from "../img/petemicky.png"

class About extends Component {
    render() {
        return (
        <div>
            <div id = "about-section">
            <div className="about-header">
                        <h1>Meet Captain Pete</h1>
                    </div>
            <div className="about-container">
                <div className="about-img">
                        <img src={Petemicky} alt="Captain Pete" />
                     </div>
                    <div className="about-text">
                <h1>About Cpatain Pete</h1>
                <p>Captain Pete is not just a character; he's the boss of none other than Mickey Mouse himself! Legend has it that Mickey tends to wander off and find trouble on the blockchain, but fear not – Captain Pete is here.</p>
                     </div>
                     
                </div>
            </div>
            </div>
        );
    }
}

export default About;